var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"periods__container",style:(_vm.dataStyles)},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"tools__container"},[_c('h1',[_vm._v("Periodos")]),_c('div',{staticClass:"tools-buttons"},[_vm._l((_vm.surveys.length),function(index){return [_c('div',{key:index},[_c('palette',{attrs:{"startColor":_vm.company.periodColors
                  ? _vm.company.periodColors[index - 1]
                  : '#15B195'},on:{"changeColor":function (color) { return _vm.changeColor(color, index - 1); }}})],1)]}),_c('div',[_c('chart-select',{attrs:{"charts":['squareBar', 'line']},model:{value:(_vm.chartType),callback:function ($$v) {_vm.chartType=$$v},expression:"chartType"}})],1),_c('custom-button',{attrs:{"variant":"secondary","download":"reporte.csv","href":_vm.downloadLink}},[_vm._v(" Excel "),_c('font-awesome-icon',{attrs:{"slot":"icon","icon":_vm.icons.excel},slot:"icon"})],1),_c('custom-button',{attrs:{"variant":"secondary"},on:{"click":_vm.downloadPPT}},[_c('font-awesome-icon',{attrs:{"slot":"icon","icon":_vm.icons.powerpoint},slot:"icon"}),_vm._v(" PPT ")],1),_c('custom-button',{attrs:{"variant":"button"},on:{"click":function($event){_vm.isModalOpen = true}}},[_c('font-awesome-icon',{attrs:{"slot":"icon","icon":_vm.icons.filter},slot:"icon"}),_vm._v(" Filtrar ")],1)],2)]),_c('div',{staticClass:"tags-container"},[_c('tag-bar',{attrs:{"filters":_vm.filters,"selectedDimensions":_vm.selectedDimensions}})],1),_c('div',{staticClass:"participation-container"},[_vm._m(0),_c('div',[(_vm.chartType === 'squareBar')?_c('bar-chart',{attrs:{"chartData":{
            dataset: Object.keys(_vm.periodsParticipation).reduce(
              function (acc, period) {
                var _obj;

                if (
                  _vm.filters.Periodos === undefined ||
                  _vm.filters.Periodos.includes(period)
                )
                  { return Object.assign({}, acc, ( _obj = {}, _obj[period] = _vm.periodsParticipation[period], _obj )); }
                else { return acc; }
              },
              {}
            ),
            legend: ['Participo']
          },"colors":_vm.company.periodColors,"showXAxis":false,"isShapeCircle":false,"sortBy":'name'}}):(_vm.chartType === 'line')?_c('line-chart',{attrs:{"chartData":{
            dataset: Object.keys(_vm.periodsParticipation).reduce(
              function (acc, period) {
                var _obj;

                if (
                  _vm.filters.Periodos === undefined ||
                  _vm.filters.Periodos.includes(period)
                )
                  { return Object.assign({}, acc, ( _obj = {}, _obj[period] = _vm.periodsParticipation[period], _obj )); }
                else { return acc; }
              },
              {}
            ),
            legend: ['Participo']
          },"colors":_vm.company.periodColors}}):_vm._e()],1)]),_c('div',{staticClass:"number-chart__container"},[_vm._m(1),_c('div',{staticClass:"number-chart__content"},[_vm._m(2),_c('div',{staticClass:"number-container"},[_c('h1',{staticClass:"primary-color"},[_c('span',[_vm._v(_vm._s(( Object.values( Object.keys(_vm.periodsSatisfactionIndex).reduce( function (acc, period) {
          var _obj;
 if ( _vm.filters.Periodos === undefined || _vm.filters.Periodos.includes(period) ) { return Object.assign({}, acc, ( _obj = {}, _obj[period] = _vm.periodsSatisfactionIndex[period], _obj )); } else { return acc; } }, {} ) ) .map(function (val) { return Number(val[0]); }) .reduce(function (a, b) { return a + b; }, 0) / _vm.surveys.length ).toFixed(0)))]),_c('span',{staticClass:"percent-symbol"},[_vm._v("%")])]),_c('div',[_c('div',{staticClass:"bar"},[_c('span',{staticClass:"fill",style:({
                  width:
                    (
                      Object.values(
                        Object.keys(_vm.periodsSatisfactionIndex).reduce(
                          function (acc, period) {
                            var _obj;

                            if (
                              _vm.filters.Periodos === undefined ||
                              _vm.filters.Periodos.includes(period)
                            )
                              { return Object.assign({}, acc,
                                ( _obj = {}, _obj[period] = _vm.periodsSatisfactionIndex[period], _obj )); }
                            else { return acc; }
                          },
                          {}
                        )
                      )
                        .map(function (val) { return Number(val[0]); })
                        .reduce(function (a, b) { return a + b; }, 0) / _vm.surveys.length
                    ).toFixed(0) + '%'
                })})])])])])]),_c('div',{staticClass:"stack-chart__container"},[_vm._m(3),_c('div',[_c('stack-bar-chart',{attrs:{"chartData":{
            labels: _vm.surveys.map(function (ref) {
                    var name = ref.name;

                    return name;
}),
            datasets: [
              {
                label: 'Promotores',
                data: _vm.surveys.map(
                  function (ref) {
                          var name = ref.name;

                          return _vm.eNPS[name].eNPSSatisfactionIndex[0];
}
                ),
                backgroundColor: _vm.company.periodColors[0]
              },
              {
                label: 'Pasivos',
                data: _vm.surveys.map(
                  function (ref) {
                          var name = ref.name;

                          return _vm.eNPS[name].eNPSSatisfactionIndex[1];
}
                ),
                backgroundColor: _vm.company.periodColors[1]
              },
              {
                label: 'Detractores',
                data: _vm.surveys.map(
                  function (ref) {
                          var name = ref.name;

                          return _vm.eNPS[name].eNPSSatisfactionIndex[2];
}
                ),
                backgroundColor: '#FD7670'
              }
            ]
          },"options":{
            tooltips: { enabled: false },
            legend: {
              // display: false,
            },
            indexAxis: 'y',
            scales: {
              xAxes: [
                {
                  stacked: true,
                  position: 'right',
                  display: false
                }
              ],
              yAxes: [
                {
                  stacked: true,
                  barPercentage: 0.6
                }
              ]
            },

            responsive: true,
            maintainAspectRatio: false
          }}})],1)]),_c('div',{staticClass:"main-chart__container"},[_c('div',{staticClass:"chart-title"},[_c('h2',[_vm._v(" Análisis de resultados "),_c('h3',[_vm._v(_vm._s(_vm.selectedCategory))])])]),_c('div',[(_vm.chartType === 'squareBar')?_c('bar-chart',{attrs:{"chartData":_vm.selectedCategory === 'Periodos'
              ? {
                  dataset: Object.keys(_vm.periodsSatisfactionIndex).reduce(
                    function (acc, period) {
                      var _obj;

                      if (
                        this$1.filters.Periodos === undefined ||
                        this$1.filters.Periodos.includes(period)
                      )
                        { return Object.assign({}, acc,
                          ( _obj = {}, _obj[period] = _vm.periodsSatisfactionIndex[period], _obj )); }
                      else { return acc; }
                    },
                    {}
                  ),
                  legend: ['Fortaleza']
                }
              : {
                  dataset: _vm.chartData,
                  legend: _vm.surveys.map(function (ref) {
                          var name = ref.name;

                          return name;
})
                },"colors":_vm.company.periodColors,"showXAxis":true,"isShapeCircle":false,"sortBy":_vm.selectedCategory === 'Periodos' ? 'name' : 'value'}}):(_vm.chartType === 'line')?_c('line-chart',{attrs:{"chartData":_vm.selectedCategory === 'Periodos'
              ? {
                  dataset: Object.keys(_vm.periodsSatisfactionIndex).reduce(
                    function (acc, period) {
                      var _obj;

                      if (
                        this$1.filters.Periodos === undefined ||
                        this$1.filters.Periodos.includes(period)
                      )
                        { return Object.assign({}, acc,
                          ( _obj = {}, _obj[period] = _vm.periodsSatisfactionIndex[period], _obj )); }
                      else { return acc; }
                    },
                    {}
                  ),
                  legend: ['Fortaleza']
                }
              : {
                  dataset: _vm.chartData,
                  legend: _vm.surveys.map(function (ref) {
                          var name = ref.name;

                          return name;
})
                },"colors":_vm.company.periodColors}}):_vm._e()],1)])]),_c('modal',{attrs:{"isOpen":_vm.isModalOpen},on:{"close":function($event){_vm.isModalOpen = false}}},[_c('template',{slot:"title"},[_vm._v("Filtrar")]),_c('div',{staticClass:"filters-container"},[_c('filter-box',{attrs:{"startCategory":_vm.selectedCategory || '',"startDimensions":_vm.selectedDimensions || {},"startFilters":_vm.filters || {},"categories":_vm.matchingCategories.slice(1, _vm.matchingCategories.length).concat( [{
            name: 'Periodos',
            options: this.survs.map(function (ref) {
                    var name = ref.name;

                    return ({
              name: name
            });
})
          }],
          [{
            name: 'Dimension',
            options: _vm.selectedSurvey.sections.filter(
              function (section) { return section.id !== 'engagement' && section.id !== 'enps'; }
            )
          }],
          [_vm.matchingCategories[0]]
        )},on:{"editFilters":_vm.handleEditFilter,"closeModal":function($event){_vm.isModalOpen = false}}})],1)],2)],1):(_vm.loading)?_c('div',{staticClass:"loading-screen"},[_c('loading-screen')],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-title"},[_c('h2',[_vm._v("Participación")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-title"},[_c('h2',[_vm._v("Promedio General")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-title"},[_c('h2',[_vm._v("Distribución eNPS")])])}]

export { render, staticRenderFns }