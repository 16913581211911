<script>
import { HorizontalBar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: ["options", "chartData"],
  mounted() {
    this.renderChart(this.chartData, {
      ...this.options,
      events: false,
      tooltips: {
        enabled: false
      },
      animation: {
        duration: 1,
        onComplete: function() {
          var chartInstance = this.chart,
            ctx = chartInstance.ctx;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";
          ctx.font = "300 14px 'Inter', sans-serif";
          ctx.fillStyle = "white";

          let prevLength = [90, 90];
          this.data.datasets.forEach(function(dataset, i) {
            var meta = chartInstance.controller.getDatasetMeta(i);
            meta.data.forEach(function(bar, index) {
              var data = dataset.data[index].toFixed(0);
              ctx.fillText(
                data + "%",
                (prevLength[index] + bar._model.x) / 2,
                bar._model.y + 7
              );
              prevLength[index] = bar._model.x;
            });
          });
        }
      }
    });
  }
};
</script>

<style scoped></style>
