<template>
  <div v-if="!loading" class="periods__container" :style="dataStyles">
    <div class="content">
      <div class="tools__container">
        <h1>Periodos</h1>
        <div class="tools-buttons">
          <template v-for="index in surveys.length">
            <div :key="index">
              <palette
                :startColor="
                  company.periodColors
                    ? company.periodColors[index - 1]
                    : '#15B195'
                "
                @changeColor="color => changeColor(color, index - 1)"
              />
            </div>
          </template>
          <div>
            <chart-select v-model="chartType" :charts="['squareBar', 'line']" />
          </div>
          <custom-button
            variant="secondary"
            download="reporte.csv"
            :href="downloadLink"
          >
            Excel
            <font-awesome-icon :icon="icons.excel" slot="icon" />
          </custom-button>
          <custom-button variant="secondary" @click="downloadPPT">
            <font-awesome-icon :icon="icons.powerpoint" slot="icon" />
            PPT
          </custom-button>
          <custom-button variant="button" @click="isModalOpen = true">
            <font-awesome-icon :icon="icons.filter" slot="icon" />
            Filtrar
          </custom-button>
        </div>
      </div>

      <div class="tags-container">
        <tag-bar :filters="filters" :selectedDimensions="selectedDimensions" />
      </div>
      <div class="participation-container">
        <div class="chart-title">
          <h2>Participación</h2>
        </div>
        <div>
          <bar-chart
            v-if="chartType === 'squareBar'"
            :chartData="{
              dataset: Object.keys(periodsParticipation).reduce(
                (acc, period) => {
                  if (
                    filters.Periodos === undefined ||
                    filters.Periodos.includes(period)
                  )
                    return { ...acc, [period]: periodsParticipation[period] };
                  else return acc;
                },
                {}
              ),
              legend: ['Participo']
            }"
            :colors="company.periodColors"
            :showXAxis="false"
            :isShapeCircle="false"
            :sortBy="'name'"
          />

          <line-chart
            v-else-if="chartType === 'line'"
            :chartData="{
              dataset: Object.keys(periodsParticipation).reduce(
                (acc, period) => {
                  if (
                    filters.Periodos === undefined ||
                    filters.Periodos.includes(period)
                  )
                    return { ...acc, [period]: periodsParticipation[period] };
                  else return acc;
                },
                {}
              ),
              legend: ['Participo']
            }"
            :colors="company.periodColors"
          />
        </div>
      </div>
      <div class="number-chart__container">
        <div class="chart-title">
          <h2>Promedio General</h2>
        </div>
        <div class="number-chart__content">
          <div>
            <h2></h2>
          </div>
          <div class="number-container">
            <h1 class="primary-color">
              <span>{{
                (
                  Object.values(
                    Object.keys(periodsSatisfactionIndex).reduce(
                      (acc, period) => {
                        if (
                          filters.Periodos === undefined ||
                          filters.Periodos.includes(period)
                        )
                          return {
                            ...acc,
                            [period]: periodsSatisfactionIndex[period]
                          };
                        else return acc;
                      },
                      {}
                    )
                  )
                    .map(val => Number(val[0]))
                    .reduce((a, b) => a + b, 0) / surveys.length
                ).toFixed(0)
              }}</span>
              <span class="percent-symbol">%</span>
            </h1>
            <div>
              <div class="bar">
                <span
                  class="fill"
                  :style="{
                    width:
                      (
                        Object.values(
                          Object.keys(periodsSatisfactionIndex).reduce(
                            (acc, period) => {
                              if (
                                filters.Periodos === undefined ||
                                filters.Periodos.includes(period)
                              )
                                return {
                                  ...acc,
                                  [period]: periodsSatisfactionIndex[period]
                                };
                              else return acc;
                            },
                            {}
                          )
                        )
                          .map(val => Number(val[0]))
                          .reduce((a, b) => a + b, 0) / surveys.length
                      ).toFixed(0) + '%'
                  }"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stack-chart__container">
        <div class="chart-title">
          <h2>Distribución eNPS</h2>
        </div>
        <div>
          <stack-bar-chart
            :chartData="{
              labels: surveys.map(({ name }) => name),
              datasets: [
                {
                  label: 'Promotores',
                  data: surveys.map(
                    ({ name }) => eNPS[name].eNPSSatisfactionIndex[0]
                  ),
                  backgroundColor: company.periodColors[0]
                },
                {
                  label: 'Pasivos',
                  data: surveys.map(
                    ({ name }) => eNPS[name].eNPSSatisfactionIndex[1]
                  ),
                  backgroundColor: company.periodColors[1]
                },
                {
                  label: 'Detractores',
                  data: surveys.map(
                    ({ name }) => eNPS[name].eNPSSatisfactionIndex[2]
                  ),
                  backgroundColor: '#FD7670'
                }
              ]
            }"
            :options="{
              tooltips: { enabled: false },
              legend: {
                // display: false,
              },
              indexAxis: 'y',
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    position: 'right',
                    display: false
                  }
                ],
                yAxes: [
                  {
                    stacked: true,
                    barPercentage: 0.6
                  }
                ]
              },

              responsive: true,
              maintainAspectRatio: false
            }"
          ></stack-bar-chart>
        </div>
      </div>
      <div class="main-chart__container">
        <div class="chart-title">
          <h2>
            Análisis de resultados
            <h3>{{ selectedCategory }}</h3>
          </h2>
        </div>
        <div>
          <bar-chart
            v-if="chartType === 'squareBar'"
            :chartData="
              selectedCategory === 'Periodos'
                ? {
                    dataset: Object.keys(periodsSatisfactionIndex).reduce(
                      (acc, period) => {
                        if (
                          this.filters.Periodos === undefined ||
                          this.filters.Periodos.includes(period)
                        )
                          return {
                            ...acc,
                            [period]: periodsSatisfactionIndex[period]
                          };
                        else return acc;
                      },
                      {}
                    ),
                    legend: ['Fortaleza']
                  }
                : {
                    dataset: chartData,
                    legend: surveys.map(({ name }) => name)
                  }
            "
            :colors="company.periodColors"
            :showXAxis="true"
            :isShapeCircle="false"
            :sortBy="selectedCategory === 'Periodos' ? 'name' : 'value'"
          />

          <line-chart
            v-else-if="chartType === 'line'"
            :chartData="
              selectedCategory === 'Periodos'
                ? {
                    dataset: Object.keys(periodsSatisfactionIndex).reduce(
                      (acc, period) => {
                        if (
                          this.filters.Periodos === undefined ||
                          this.filters.Periodos.includes(period)
                        )
                          return {
                            ...acc,
                            [period]: periodsSatisfactionIndex[period]
                          };
                        else return acc;
                      },
                      {}
                    ),
                    legend: ['Fortaleza']
                  }
                : {
                    dataset: chartData,
                    legend: surveys.map(({ name }) => name)
                  }
            "
            :colors="company.periodColors"
          />
        </div>
      </div>
    </div>

    <modal :isOpen="isModalOpen" @close="isModalOpen = false">
      <template slot="title">Filtrar</template>
      <div class="filters-container">
        <filter-box
          :startCategory="selectedCategory || ''"
          :startDimensions="selectedDimensions || {}"
          :startFilters="filters || {}"
          v-on:editFilters="handleEditFilter"
          :categories="[
            ...matchingCategories.slice(1, matchingCategories.length),
            {
              name: 'Periodos',
              options: this.survs.map(({ name }) => ({
                name
              }))
            },
            {
              name: 'Dimension',
              options: selectedSurvey.sections.filter(
                section => section.id !== 'engagement' && section.id !== 'enps'
              )
            },
            matchingCategories[0]
          ]"
          v-on:closeModal="isModalOpen = false"
        ></filter-box>
      </div>
    </modal>
  </div>

  <div class="loading-screen" v-else-if="loading"><loading-screen /></div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import BarChart from "@/components/charts/BarChart.vue";
import StackBarChart from "@/components/charts/StackBarChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import ChartSelect from "../components/dashboard/ChartSelect.vue";
import FilterBox from "../components/dashboard/FilterBox.vue";
import Modal from "../components/Modal.vue";
import CustomButton from "../components/CustomButton.vue";
import TagBar from "../components/dashboard/TagBar.vue";
import LoadingScreen from "../components/LoadingScreen.vue";
import Palette from "../components/dashboard/Palette.vue";
import pptxgen from "pptxgenjs";

import {
  faSlidersH,
  faFilePowerpoint,
  faFileExcel
} from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    BarChart,
    TagBar,
    ChartSelect,
    LineChart,
    StackBarChart,
    FilterBox,
    Modal,
    CustomButton,
    LoadingScreen,
    Palette
  },

  data() {
    return {
      loading: true,
      selectedCategory: "Periodos",
      periodsSatisfactionIndex: {},
      periodsParticipation: {},
      periodsParticipationTotal: 0,
      eNPS: {},
      chartData: {},
      selectedDimensions: {},
      filters: {},
      chartType: "squareBar",
      isModalOpen: false,
      icons: {
        filter: faSlidersH,
        powerpoint: faFilePowerpoint,
        excel: faFileExcel
      },
      categoriesGroup: []
    };
  },
  methods: {
    ...mapActions([
      "fetchCurrentSurvey",
      "fetchSections",
      "getParticipationData",
      "fetchAllSurveys",
      "getPeriodSatisfactionIndex",
      "updateCompanyPeriodColors"
    ]),

    ...mapActions("sections", ["fetchSections", "fetchQuestions"]),

    ...mapActions("scale", ["fetchScale"]),

    ...mapActions("filters", ["getFiltersBySurveyId"]),

    ...mapMutations(["setCurrentSurvey"]),

    changeColor(color, index) {
      let colorsCopy = [...this.company.periodColors];
      colorsCopy[index] = color;
      this.updateCompanyPeriodColors(colorsCopy);
      this.company.periodColors = colorsCopy;
    },

    async handleEditFilter(filters) {
      this.loading = true;
      this.categoriesGroup = [];
      this.filters = { ...filters.selectedFilters };
      this.selectedDimensions = { ...filters.selectedDimensions };
      this.selectedCategory = filters.selectedCategory;
      this.isModalOpen = false;
      this.chartType = "squareBar";
      await this.setDashboardData();
    },

    async changeSurvey(survey) {
      this.setCurrentSurvey(survey);
      await this.fetchQuestions();
      await this.fetchSections();
      await this.fetchScale();
      let tempCats = await this.getCategories(survey);
      this.categoriesGroup.push(tempCats);
      return tempCats;
    },

    async getPeriodData(survey, categories) {
      if (!this.loading) this.loading = true;
      let periodData = {};
      try {
        /* eslint-disable */
        let result = await this.getPeriodSatisfactionIndex({
          selectedCategory: this.selectedCategory,
          surveyId: survey.id,
          filters: (({ Periodos, ...others }) => others)(this.filters),
          selectedDimensions: this.selectedDimensions,
          categories,
        });
        periodData.chartData = result.chartData;
        periodData.participationData = result.participationData;
        periodData.satisfactionIndexOfCompany = result.satisfaction;
        periodData.eNPSData = result.eNPSData;
        return periodData;
      } catch (error) {
        console.log('Error getDashboardData cloud function: ' + error);
      }
      this.loading = false;
    },

    async setDashboardData() {
      let index = 0;
      this.periodsParticipationTotal = 0;
      let orderedSurveys = this.surveys
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map(({ name }) => name);
      await this.surveys.forEach(async (survey) => {
        let categories = await this.changeSurvey(survey);
        let temp = await this.getPeriodData(survey, categories);
        this.periodsSatisfactionIndex[survey.name] = [
          temp.satisfactionIndexOfCompany[0],
        ];
        let participation = parseInt(
          temp.participationData.filteredUserLength +
            temp.participationData.totalWrittenAnswers ===
            0
            ? 0
            : (
                (temp.participationData.filteredAnswersLength /
                  (temp.participationData.filteredUsersLength +
                    temp.participationData.totalWrittenAnswers)) *
                100
              ).toFixed(0),
        );
        this.eNPS[survey.name] = temp.eNPSData;
        this.periodsParticipation[survey.name] = [participation];
        this.periodsParticipationTotal +=
          parseInt(temp.participationData.filteredUsersLength) +
          parseInt(temp.participationData.totalWrittenAnswers);
        if (this.selectedCategory !== 'Periodos') {
          if (index === 0) {
            let cat = {};
            Object.keys(temp.chartData.dataset).forEach((category) => {
              cat[category] = [];
            });
            this.chartData = cat;
          }
          Object.keys(temp.chartData.dataset).forEach((category) => {
            if (this.chartData[category] !== undefined)
              this.chartData[category][orderedSurveys.indexOf(survey.name)] =
                temp.chartData.dataset[category][0];
          });
        }

        index++;
        if (index === this.surveys.length) {
          this.loading = false;
          Object.keys(this.chartData).forEach((category) => {
            if (
              this.chartData[category].includes(undefined) ||
              this.chartData[category].length !== this.surveys.length
            )
              delete this.chartData[category];
          });
        }
      });
    },

    async getCategories(survey) {
      let categories = await this.getFiltersBySurveyId(survey.id);
      return [
        {
          id: 'question',
          name: 'Pregunta',
          options: this.selectedSurvey.questions,
        },
        ...categories.sort((x, y) =>
          x.orgHierarchy === y.orgHierarchy
            ? x.hasParent === y.hasParent
              ? 0
              : x.hasParent
              ? 1
              : -1
            : x.orgHierarchy
            ? -1
            : 1,
        ),
      ];
    },

    rgbToHex(r, g, b) {
      return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    },

    randomColor(r, g, b) {
      var maxMin = this.getMaxMin(r);
      var rN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      maxMin = this.getMaxMin(g);
      var gN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      maxMin = this.getMaxMin(b);
      var bN = Math.floor(
        Math.random() * (maxMin[0] - maxMin[1] + 1) + maxMin[1],
      );
      return this.rgbToHex(rN, gN, bN);
    },
    getMaxMin(value) {
      var max = value + 30 < 255 ? value + 30 : 255;
      var min = value + 80 < 255 ? value + 80 : 255;
      return [max, min];
    },

    generateColors() {
      if (!this.company.periodColors) {
        let tempColors = ['#15b195', '#A4D6C9'];
        for (let i = 0; i < this.surveys.length; i++) {
          tempColors.push(this.randomColor(104, 208, 158));
        }
        this.updateCompanyPeriodColors(tempColors);
        this.company.periodColors = tempColors;
      }
    },

    downloadPPT() {
      let pptx = new pptxgen();
      let slide = pptx.addSlide();

      //5.62 tamaño ppt
      slide.addText('Informe de Resultados', {
        x: 0,
        y: 2.61,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 32,
      });

      let text = '';
      Object.keys(this.filters).forEach((key) => {
        text += ' / ' + key + ': ';
        this.filters[key].forEach((filter, index) => {
          text += filter + (index == this.filters[key].length - 1 ? '' : ', ');
        });
      });

      if (Object.keys(this.selectedDimensions).length) {
        text += ' / Dimension: ';
        Object.keys(this.selectedDimensions).forEach((key, index) => {
          text +=
            this.selectedDimensions[key] +
            (index == Object.keys(this.selectedDimensions).length - 1
              ? ''
              : ', ');
        });
        text += ' / ';
      }

      slide.addText(text, {
        x: 0,
        y: 3.1,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 14,
      });
      slide = pptx.addSlide();
      let periodChartData = [];

      let labels = Object.keys(this.periodsSatisfactionIndex).sort((a, b) =>
        parseInt(this.periodsSatisfactionIndex[a][0]) >
        parseInt(this.periodsSatisfactionIndex[b][0])
          ? -1
          : parseInt(this.periodsSatisfactionIndex[b][0]) >
            parseInt(this.periodsSatisfactionIndex[a][0])
          ? 1
          : 0,
      );
      let values = [];
      labels.forEach((label) =>
        values.push(this.periodsSatisfactionIndex[label][0] / 100),
      );
      periodChartData.push({
        name: 'Fortaleza',
        labels: labels,
        values: values,
      });

      slide.addText([{ text: text }], {
        x: 0,
        y: 1,
        w: '100%',
        h: 0.1,
        fontSize: 8,
        align: 'center',
      });

      let CHART_CONFIG = {
        x: 1,
        y: 1.2,
        w: 8,
        h: 4.3,
        catAxisLabelColor: '000000',
        catAxisLabelFontSize: 8,
        catAxisOrientation: 'minMax',
        valAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        legendFontSize: 8,
        titleFontSize: 10,
        chartColors: this.company.periodColors.slice(0, 1),
        dataLabelPosition: 't',
        dataLabelFormatCode: '#%',
        valAxisLabelFormatCode: '#%',
        showValue: true,
        showTitle: true,
        showLegend: true,
        legendPos: 't',
        valAxisMaxVal: 1,
        title: 'Análisis de resultados',
      };

      slide.addChart(pptx.ChartType.bar, periodChartData, CHART_CONFIG);

      let temp = (
        Object.values(this.periodsSatisfactionIndex)
          .map((val) => Number(val[0]))
          .reduce((a, b) => a + b, 0) / this.surveys.length
      ).toFixed(0);
      let satisfactionIndexOfCompany = [temp, 100 - temp];
      border = { pt: '2', color: this.company.periodColors[0] };
      slide.addTable(
        [
          [
            {
              text: 'Fortaleza',
              options: {
                border: [border, border, null, border],
                margin: [0.12, 0.12, 0, 0.12],
                fontSize: 8,
              },
            },
          ],
          [
            {
              text: satisfactionIndexOfCompany[0] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 3.35,
          y: 0.3,
          w: 1.5,
          color: this.company.periodColors[0],
          align: 'left',
          border: { pt: '2', color: this.company.periodColors[0] },
        },
      );

      let border = { pt: '2', color: this.company.periodColors[1] };
      slide.addTable(
        [
          [
            {
              text: 'Oportunidad',
              options: {
                border: [border, border, null, border],
                fontSize: 8,
                margin: [0.12, 0.12, 0, 0.12],
              },
            },
          ],
          [
            {
              text: satisfactionIndexOfCompany[1] + '%',
              options: {
                border: [null, border, border, border],
                margin: [0, 0.12, 0.12, 0.12],
                bold: true,
              },
            },
          ],
        ],
        {
          x: 5.05,
          y: 0.3,
          w: 1.5,
          color: this.company.periodColors[1],
          align: 'left',
        },
      );

      const generalDoughnutData = [
        {
          name: 'Resultado General',
          labels: ['Fortaleza', 'Oportunidad'],
          values: satisfactionIndexOfCompany,
        },
      ];

      let DOUGHNUT_CONFIG = {
        x: 4.25,
        y: 0.3,
        w: 0.57,
        h: 0.57,
        chartColors: [this.company.periodColors[0], 'F5F6FA'],
        dataLabelColor: 'FFFFFF',
        dataLabelFontSize: 14,
        holeSize: 80,
        legendPos: 'b',
        showPercent: false,
        showLegend: false,
        showTitle: false,
        dataLabelPosition: 'ctr',
        titleFontFace: 'Helvetica Neue',
        titleFontSize: 10,
        title: 'Promedio General',
      };

      slide.addChart(
        pptx.charts.DOUGHNUT,
        generalDoughnutData,
        DOUGHNUT_CONFIG,
      );

      slide.addChart(pptx.charts.DOUGHNUT, generalDoughnutData, {
        ...DOUGHNUT_CONFIG,
        x: 5.95,
        chartColors: ['F5F6FA', this.company.periodColors[1]],
      });

      slide = pptx.addSlide();
      let mainChartData = [];
      let satisfaction = {};
      Object.keys(this.chartData).forEach((category) => {
        satisfaction[category] = this.chartData[category];
      });
      labels = Object.keys(this.chartData).sort((a, b) =>
        parseInt(satisfaction[a][0]) > parseInt(this.chartData[b][0])
          ? -1
          : parseInt(satisfaction[b][0]) > parseInt(this.chartData[a][0])
          ? 1
          : 0,
      );
      this.survs
        .map(({ name }) => name)
        .forEach((label, index) => {
          let values = [];
          labels.forEach((label) =>
            values.push(satisfaction[label][index] / 100),
          );
          mainChartData.push({
            name: label,
            labels: labels,
            values: values,
          });
        });
      slide.addChart(pptx.ChartType.bar, mainChartData, {
        ...CHART_CONFIG,
        y: 0.2,
        h: 5.3,
        chartColors: this.company.periodColors,
      });

      slide = pptx.addSlide();

      let participationChartData = [];

      labels = Object.keys(this.periodsParticipation).sort((a, b) =>
        parseInt(this.periodsParticipation[a][0]) >
        parseInt(this.periodsParticipation[b][0])
          ? -1
          : parseInt(this.periodsParticipation[b][0]) >
            parseInt(this.periodsParticipation[a][0])
          ? 1
          : 0,
      );
      values = [];
      labels.forEach((label) =>
        values.push(this.periodsParticipation[label][0] / 100),
      );
      participationChartData.push({
        name: 'Participo',
        labels: labels,
        values: values,
      });

      slide.addChart(pptx.ChartType.bar, participationChartData, {
        ...CHART_CONFIG,
        y: 0.2,
        h: 5.3,
        title: 'Participación',
      });

      slide = pptx.addSlide();
      let eNPSChartData = [];
      let eNPS = {};
      Object.keys(this.eNPS).forEach((period) => {
        eNPS[period] = this.eNPS[period].eNPSSatisfactionIndex;
      });
      labels = Object.keys(this.eNPS).sort((a, b) =>
        parseInt(eNPS[a][0]) > parseInt(this.eNPS[b][0])
          ? -1
          : parseInt(eNPS[b][0]) > parseInt(this.eNPS[a][0])
          ? 1
          : 0,
      );
      ['Promotores', 'Pasivos', 'Detractores'].forEach((label, index) => {
        let values = [];
        labels.forEach((label) => values.push(eNPS[label][index] / 100));
        eNPSChartData.push({
          name: label,
          labels: labels,
          values: values,
        });
      });
      slide.addChart(pptx.ChartType.bar, eNPSChartData, {
        ...CHART_CONFIG,
        y: 0.2,
        h: 5.3,
        barDir: 'bar',
        barGrouping: 'stacked',
        chartColors: [
          this.company.periodColors[0],
          this.company.periodColors[1],
          '#FD7670',
        ],
        title: 'Distribución eNPS',
      });

      pptx.writeFile({ fileName: 'dashboard.pptx' });
    },

    CSVtoArray(text) {
      return text.replace(/,/g, '').replace(/(\r\n|\n|\r)/gm, '');
    },

    getReportCSV() {
      let csv = [];

      csv.push([
        '',
        'Satisfacción',
        'Participación',
        'Promotores',
        'Pasivos',
        'Detractores',
        Object.keys(this.chartData).map((val) => this.CSVtoArray(val)),
      ]);
      this.surveys.forEach((survey, index) => {
        csv.push([
          '\n' + survey.name,
          this.periodsSatisfactionIndex[survey.name][0] + '%',
          this.periodsParticipation[survey.name][0] + '%',
          ...this.eNPS[survey.name].eNPSSatisfactionIndex
            .reverse()
            .map((val) => val.toFixed(0) + '%'),
          Object.values(this.chartData).map((val) => val[index]),
        ]);
      });

      return csv;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchAllSurveys(this.company.id);
      await this.setDashboardData();
    } catch (err) {
      console.log('error fetching');
    }

    this.generateColors();
  },
  computed: {
    ...mapState(['company', 'survey']),

    ...mapState({
      survs: (state) => {
        return state.surveys.sort(function(a, b) {
          return a.name > b.name ? 1 : a.name === b.name ? 0 : -1;
        });
      },
    }),

    ...mapState({
      selectedSurvey: (state) => {
        return {
          ...state.survey,
          sections: state.sections.sections,
          questions: state.sections.questions,
          scale: state.scale.scale,
        };
      },
    }),

    surveys() {
      return this.survs.filter(
        ({ name }) =>
          this.filters.Periodos === undefined ||
          this.filters.Periodos.includes(name),
      );
    },

    matchingCategories() {
      let matchingCategories = [];
      if (this.categoriesGroup.length) {
        this.categoriesGroup[0].forEach((category) => {
          let isAMatch = true;
          let categoriesOptions = [];
          this.categoriesGroup.forEach((categories) => {
            let searchCategory = categories.filter(
              ({ name }) => category.name === name,
            );
            if (!searchCategory.length) isAMatch = false;
            else categoriesOptions.push(...searchCategory[0].options);
          });
          if (isAMatch) {
            let propertyIdentifier =
              category.id === 'question' ? 'text' : 'name';
            let temp = {};
            categoriesOptions.forEach((option) => {
              if (option[propertyIdentifier] in temp)
                temp[option[propertyIdentifier]] += 1;
              else temp[option[propertyIdentifier]] = 1;
            });
            matchingCategories.push({
              ...category,
              options: categoriesOptions
                .filter(
                  (option) =>
                    temp[option[propertyIdentifier]] === this.surveys.length,
                )
                .filter(
                  (value, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        t.place === value.place &&
                        t[propertyIdentifier] === value[propertyIdentifier],
                    ),
                ),
            });
          }
        });
      }
      return matchingCategories;
    },

    dataStyles() {
      return {
        '--primary-selected-color': this.company.periodColors[0],
        '--secondary-selected-color': this.company.periodColors[1],
        '--nth2-selected-color': this.company.periodColors[2],
        '--nth3-selected-color': this.company.periodColors[3],
      };
    },

    downloadLink() {
      let csvFileData = this.getReportCSV();
      var csvContent = '\uFEFF' + csvFileData;
      var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      var url = URL.createObjectURL(blob);
      return url;
    },
  },
};
</script>

<style scoped>
.periods__container {
  padding: var(--lengthMd2) var(--lengthLg1) 0 var(--lengthLg1);
}
.content {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'tools tools tools tools'
    'tag tag tag tag'
    'participation participation ENPS ENPS'
    'bar main main main'
    'bar main main main';
  grid-template-rows: 9% 3% 34% 0% 44%;
  grid-template-columns: 24% 24% 24% 24%;
  -moz-column-gap: 1%;
  column-gap: 1%;
  row-gap: 2%;
  font-size: 14px;
}

.tools__container {
  grid-area: tools;
  display: flex;
  justify-content: space-between;
  user-select: none;
  padding: 0 1em;
}
.tags__container {
  grid-area: tag;
}
.main-chart__container {
  grid-area: main;
}

.participation-container > div:nth-child(2),
.main-chart__container > div:nth-child(2),
.number-chart__container > div:nth-child(2),
.stack-chart__container > div:nth-child(2) {
  flex-grow: 1;
}

.number-chart__container {
  grid-area: bar;
}

.participation-container {
  grid-area: participation;
  /* padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
  position: relative; */
}

.stack-chart__container {
  grid-area: ENPS;
}

.stack-chart__container > div:nth-child(2) {
  height: calc(100% - 1.5em);
}

.stack-chart__container > div:nth-child(2) > div {
  height: 100%;
}

.participation-container,
.number-chart__container,
.main-chart__container,
.stack-chart__container {
  padding: 1em 1em 0.2em 1em;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
  border: solid 3px #f7f7fc;
}

.tools-buttons {
  display: flex;
  align-items: flex-end;
}

.tools-buttons > * {
  margin: 0 0.8em;
}

.number-chart__content {
  display: flex;
  width: 100%;
  flex-flow: column;
}

.number-container h1 {
  font-size: 5.5em;
}

.number-container h1 span:not(.percent-symbol) {
  font-size: 1em;
}

.number-chart__container .chart-title h2 {
  margin-bottom: 0;
}

.number-chart__content > div > div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.number-chart__content > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.number-chart__content > div:nth-child(2) {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.primary-color {
  color: var(--primary-selected-color);
}

.secondary-color {
  color: var(--secondary-selected-color);
}

.percent-symbol {
  font-size: 0.65em;
  width: fit-content;
}

.number-container > div {
  display: flex;
  flex-flow: column;
  gap: 0.5em;
}
.bar {
  width: 7rem;
  height: 0.5em;
  border-radius: 10px;
  background-color: #f5f6fa;
  position: relative;
  overflow: hidden;
}

.fill {
  position: absolute;
  height: 100%;
  background-color: var(--primary-selected-color);
}

.number-chart__content h3 {
  color: var(--fontColor3);
  font-size: 1em;
}

.tags-container {
  padding-left: 1em;
  grid-area: tag;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loading-screen {
  flex-grow: 1;
}

.chart-title h2 {
  font-size: 1.2em;
  margin-bottom: 0.5rem;
}

.chart-title h3 {
  font-size: 1em;
  color: var(--fontColor3);
}
</style>
